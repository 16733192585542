import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Data from './data/contact.json';
import Form from './form';

const useStyles = makeStyles(() => ({
  contactTitle: {
    margin: "0",
    fontFamily: 'Noto Serif, serif',
    fontSize: '50px',
    color: 'white',
    textAlign: 'center',
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  contactContext: {
    backgroundColor: '#202020',
    paddingBottom: '50px',
  },
  formContent: {
    textAlign: 'center',

  },
}));

const Contact = () => {
  const {contactTitle, contactContext, formContent} = useStyles();

  return (
    <section className={contactContext} id='contactSection'>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={1} sm={2} />
          <Grid xs={10} sm={8}>
            <h1 className={contactTitle}>{Data.mainText}</h1>
            <Form />
          </Grid>
          <Grid xs={1} sm={2} />
        </Grid>
      </Grid>
    </section>
  );
};

export default Contact;