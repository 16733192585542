import React, { useState} from 'react';
import { Grid, Box, Typography, Avatar } from '@material-ui/core';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import WebhookIcon from '@mui/icons-material/Webhook';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import { makeStyles } from '@material-ui/styles';
import Data from './data/Service.json'

const useStyles = makeStyles(() => ({
  techContext: {
    paddingTop: "50px",
  },
  techTitle: {
    margin: "0",
    fontFamily: 'Noto Serif, serif',
    fontSize: '50px',
    color: 'white',
    textAlign: 'center',
    paddingBottom: '50px',
  },
  itemGrid: {
    backgroundColor: '#303030'
  },
  serviceBody: {
    color: 'white'
  }
}));

const ICONS = {
  DisplaySettings: DisplaySettingsIcon,
  WebHook: WebhookIcon,
  WebStore: WebStoriesIcon,
}

const Service = () => {
  const { techContext, techTitle, serviceBody, serviceTitle} = useStyles();

  const [state, setState] = useState({
    isAnimaton: false,
  });

  React.useEffect( () => {
    const isAnimationActive = () => {
      return window.scrollY > 763 && window.scrollY < 909
        ? setState((prevState) => ({ ...prevState, isAnimaton: true }))
        : setState((prevState) => ({ ...prevState, isAnimaton: false }));
    };
    isAnimationActive();
  }, []);

  if (window.scrollY > 763 && window.scrollY < 909) {

  }

  const Services = serviceContent => {
    const IconComponent = ICONS[serviceContent.icon]
    return (
    <Grid item xs={12} md={4} key={serviceContent.index}>
      <Box display="flex" alignItems="center" marginBottom={1}>
        <Avatar>
          <IconComponent />
        </Avatar>
        <Typography variant="h3" className={serviceTitle} style={{ fontWeight: 'bold', marginLeft: '2%', flex: 1, color: 'white', fontSize: '30px'}}>
          {serviceContent.title}
        </Typography>
      </Box>
      <Typography className={serviceBody}>{serviceContent.body}</Typography>
    </Grid>
    );
  };
  return (
    <section className={techContext} id='techSection'>
      <h1 className={techTitle}>{Data.mainText}</h1>
      <Grid container spacing={5} id="techGrid">
        {Data.list.map(serviceContent => Services(serviceContent))}
      </Grid>
    </section>
  );
}

export default Service;
