import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, MenuItem, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState, useEffect } from "react";
import HeaderData from './data/Header.json';
import { Link as Scroll } from 'react-scroll';
import { MemoryRouter} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFEFE",
    paddingRight: "79px",
    paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "'Italianno', cursive",
    fontSize: 40,
    fontWeight: 600,
    color: "#303030",
    textAlign: "left",
    "@media (max-width: 900px)": {
      fontSize: 30,
    },
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    color: "#777",
    fontWeight: 900,
    size: "18px",
    marginLeft: "38px",
    textTransform: 'capitalize',
    "@media (max-width: 900px)": {
      fontFamily: "Open Sans, sans-serif",
      color: "#777",
      marginLeft: "0px",
      textTransform: 'capitalize',
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

const Header = () => {
  const {header, toolbar, menuButton, drawerContainer } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);


  const CompanyLogo = () => {
    const {logo} = useStyles();
    return (
      <Typography variant="h6" className={logo}>{HeaderData.title}</Typography>
    );
  }
  
  const DisplayNomal = () => {
    return (
      <Toolbar className={toolbar}>
        {CompanyLogo()}
        <div>
          {HeaderData.menu.map(({ label, scrollTarget}) => {
            return (
              <Scroll to={scrollTarget} smooth={true}>
                <Button variant="text" className={menuButton}>{label}</Button>
              </Scroll>
            )
          })}
        </div>
      </Toolbar>
    );
  };
  
  const DisplayMovile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));

    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
      <div>{CompanyLogo()}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return HeaderData.menu.map(({ label, scrollTarget }) => {
      return (
        <MemoryRouter>
          <Scroll to={scrollTarget} smooth={true} onClick={ () => {
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
          }}>
            <MenuItem className={menuButton}>{label}</MenuItem>
          </Scroll>
        </MemoryRouter>
      );
    });
  };

  return (
    <>
      <AppBar position="static" className={header}>
        {mobileView ? DisplayMovile() : DisplayNomal()}
      </AppBar>
    </>
  );
};

export default Header;