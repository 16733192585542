import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Data from './data/about.json';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  aboutTitle: {
    margin: "0",
    fontFamily: 'Noto Serif, serif',
    fontSize: '50px',
    color: 'white',
    textAlign: 'center',
    paddingBottom: '50px',
  },
  aboutContext: {
    paddingTop: '50px',
    paddingBottom: '50px',
    width: '100%',
  },
  aboutTable: {
    backgroundColor: '#303030',
  }
}));

export default function CustomPaginationActionsTable() {
  const { aboutTitle, aboutContext, aboutTable } = useStyles();
  const tableBody = tableData => {
    return (
      <TableRow key={tableData.name}>
        <TableCell component="th" scope="row" style={{ width: 50, color: 'white', fontWeight: 500}}>
          {tableData.title}
        </TableCell>
        <TableCell style={{ width: 160, color: 'white', fontWeight: 500 }} align="">
          {tableData.body}
        </TableCell>
      </TableRow>
    );
  };

  const AboutTable = () => {
    return (
      <TableContainer className={aboutTable} component={Paper}>
        {/* <Table sx={{ minWidth: 500 }} aria-label="custom pagination table"> */}
        <Table aria-label="custom pagination table">
          <TableBody>
            {Data.cellList.map(tableContents => tableBody(tableContents))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <section className={aboutContext} id='aboutSection'>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={1} />
          <Grid xs={10} sm={12}>
            <h1 className={aboutTitle}>{Data.mainText}</h1>
            <AboutTable />
          </Grid>
          <Grid xs={1} />
        </Grid>
      </Grid>
    </section>
  );
}
