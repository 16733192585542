import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Form.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LfsCXwcAAAAAFqM0ZegghPHimlsZLMhgVeiEwRQ"
    language="ja"
    // useRecaptchaNet="[optional_boolean_value]"
    // useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
reportWebVitals();
