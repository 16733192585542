import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  footer: {
    height: '5em',
    position: 'relative',
  },
  p: {
    position: 'absolute',
    margin: '0',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontWeight: '500'
  },
}));

const Footer = () => {
  const {footer, p} = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <Toolbar className={footer}>
      <p className={p}>Copyright © {currentYear} 株式会社ピアレス</p>
    </Toolbar>
  );

};

export default Footer;
