import React from 'react';
import MainImg from '../mainImage.jpg'
import { makeStyles } from '@material-ui/styles';
import typeText from './data/Home.json';
import Typist from "react-typist";

const useStyles = makeStyles(() => ({
  mainImage: {
    width: "100%",
    height: "800px",
    objectFit: 'cover',
    backgroundImage: `url(${MainImg})`,
    backgroundSize: "cover",
    backgroundPosition: "50%",
    overflow: "hidden",
    "@media (max-width: 900px)": {
      height: "350px",
    },
  },
  homeDiv: {
    width: "100%",
    height: "100%",
  },
  MainMessage: {
    margin: "0",
    fontFamily: 'Noto Serif, serif',
    fontSize: '50px',
    color: 'white',
    position: 'relative',
    top: '25%',
    left: '10%',
    "@media (max-width: 900px)": {
      top: '5%',
      left: '5%',
      fontSize: '30px',
      paddingLeft: 0,
    },
  },
  SubMessage: {
    margin: "0",
    fontFamily: 'Noto Serif, serif',
    fontSize: '50px',
    color: 'white',
    position: 'relative',
    top: '25%',
    left: '30%',
    "@media (max-width: 1300px)": {
      top: '25%',
      left: '35%',
      fontSize: '30px',
      paddingLeft: 0,
    },
    "@media (max-width: 900px)": {
      top: '10%',
      left: '5%',
      fontSize: '30px',
      paddingLeft: 0,
    },
  },

}));

const Home = () => {
    const {mainImage, homeDiv, MainMessage, SubMessage} = useStyles();
    return (
      <section className={mainImage} id='homeSection' >
        <div className={homeDiv}>
          <h1 className={MainMessage}>
            <Typist>
              {typeText.mainText}
            </Typist>
          </h1>
          <h3 className={SubMessage}>
            <Typist>
              <Typist.Delay ms={4000} />
              {typeText.subText}
            </Typist>
          </h3>
        </div>
      </section>
    );
};

export default Home;
